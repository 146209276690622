<template>
    <div class="content">
        <div class="codeTitle">
            <span>{{ $t('center.DailyCheckIn') }}</span>
        </div>
        <div :class="dialog ? 'checkInDiv headerCheckIn' : 'checkInDiv'">
            <div class="checkWrapTitle"> {{ $t('center.DailyCheckIn') }} <img
                    src="data:image/webp;base64,UklGRhgCAABXRUJQVlA4WAoAAAAQAAAAEQAAOwAAQUxQSPgAAAABgI5t297mQQhCEGqwIMygMyjCZjCE1KAIQQhCDFqD6/rxPM/bD4KImIAop8t6W89zDF7uZv6nZlUEcJ+KXzPmLc0UCuJXRKzWqLhFxC6oWNwj4lBRUdQp4mAgzxGHIljSiFJII/YJpaGyxrLDmmeQBlVUkUJBQdRGUFFpUMw40jcCiECDgqI2GRRGQBArVFGxwoIEyQzWiUIQIDleoCRQTIqjY6CSwMqcVFREKnA4qTQMDD+DUCAUajWI2KAiCBW2YDWIz/k5i8+e4/rUFvtThh9xvOD21B7LU+eI7YljjpgfY98REadj4PET5XxttlP08+W2rX9LZFZQOCD6AAAAcAcAnQEqEgA8AD6dQpxJqBdU0gbYWAnEoAuF/tKXWxgHNnyvnAlN0QuU4FLG+UTOTLqx0MwI3QujQORE/D1jRoGZTeAA9ySa2uACg0tjGECtazEhu/Ryeu3NmaTMcehmclIyc0obdtb5MwNsC2Ql8oOyk76Ghd45VJmSQa02YvJwXvyxznrXq/AuxtCp9QxUkSjJvhNkHpRt79+1ZQLTK4IZ8y+4gBG1RIT36HyQjBoarNuphkVez2WSINEzHoT0BTE3FNIN1IC06ysl8TNgpmeaV8MctmIjCKoCQbIYpkL7Qw6LSUjVrDCOYDISY8aCtoxUu2N4dNAgAA=="
                    alt="" class="checkWrapTitleImg"/><img
                    src="data:image/webp;base64,UklGRhgCAABXRUJQVlA4WAoAAAAQAAAAEQAAOwAAQUxQSPgAAAABgI5t297mQQhCEGqwIMygMyjCZjCE1KAIQQhCDFqD6/rxPM/bD4KImIAop8t6W89zDF7uZv6nZlUEcJ+KXzPmLc0UCuJXRKzWqLhFxC6oWNwj4lBRUdQp4mAgzxGHIljSiFJII/YJpaGyxrLDmmeQBlVUkUJBQdRGUFFpUMw40jcCiECDgqI2GRRGQBArVFGxwoIEyQzWiUIQIDleoCRQTIqjY6CSwMqcVFREKnA4qTQMDD+DUCAUajWI2KAiCBW2YDWIz/k5i8+e4/rUFvtThh9xvOD21B7LU+eI7YljjpgfY98REadj4PET5XxttlP08+W2rX9LZFZQOCD6AAAAcAcAnQEqEgA8AD6dQpxJqBdU0gbYWAnEoAuF/tKXWxgHNnyvnAlN0QuU4FLG+UTOTLqx0MwI3QujQORE/D1jRoGZTeAA9ySa2uACg0tjGECtazEhu/Ryeu3NmaTMcehmclIyc0obdtb5MwNsC2Ql8oOyk76Ghd45VJmSQa02YvJwXvyxznrXq/AuxtCp9QxUkSjJvhNkHpRt79+1ZQLTK4IZ8y+4gBG1RIT36HyQjBoarNuphkVez2WSINEzHoT0BTE3FNIN1IC06ysl8TNgpmeaV8MctmIjCKoCQbIYpkL7Qw6LSUjVrDCOYDISY8aCtoxUu2N4dNAgAA=="
                    alt="" class="checkWrapTitleImg checkWrapTitleImg2"/>
                <div class="check-wave"></div>
            </div>
            <div class="checkCenter">
                <div class="checkList">
                    <div :class="((i+1) === data.today && !data.isChecked) ? 'checkListItem checkListTitleBorder' : 'checkListItem'" v-for="item, i in data.daily" :key="i">
                        <div :class="i < data.today || (data.isChecked && (i+1) === data.today) ? 'checkListTitle checkListTitleAlready' : 'checkListTitle'"> {{$t('center.Day', {day: i+1})}} <span
                                class="checkListTitleColumn"></span><span
                                class="checkListTitleColumn checkListTitleColumn1"></span></div>
                        <div class="checkListImgs">
                            <div class="checkListImg" v-for="it, j in item.goodsPackDetail" :key="j">
                                <img :src="require(`@/assets/imagine/goods/${it.cover}`)"
                                    alt=""/><span>×{{ it.count }}</span></div>
                        </div><svg xmlns="http://www.w3.org/2000/svg" width="35" height="34"
                            viewBox="0 0 35 34" fill="none" class="isCheckIn" :style="i < data.today ? '' : 'display: none'">
                            <path
                                d="M7.40314 11.4149L0.739746 16.9936C8.05425 22.5724 8.22972 28.3576 7.40314 30.5529C10.0644 30.5529 12.3618 30.9681 15.1511 32.5674C17.5504 21.9667 26.541 12.4996 33.7468 9.09035C31.3482 6.13123 29.3105 3.91892 26.541 0.567383C19.4716 7.936 15.1511 14.1072 12.6655 21.1063C12.6655 18.7064 10.0984 13.6414 7.40314 11.4149Z"
                                fill="#46CB3A" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                </div>
                <div class="checkBottom">
                    <div class="checkBottomButton"><button @click="checkIn" :class="isChecked ? 'alreadyCheck' : ''"><span>{{ $t('center.CheckIn') }}</span></button></div>
                    <div class="checkAllInBorder"></div>
                    <div class="checkAllIn">
                        <div class="checkAllInLeft">
                            <div>{{ $t('center.TotalCheckIns') }}</div>
                            <p><span>{{ data.checkDay }}</span>{{ $t('center.Days') }} </p>
                        </div>
                        <div class="checkAllInCenter"><img
                                src="data:image/webp;base64,UklGRrYAAABXRUJQVlA4WAoAAAAQAAAAGAAADQAAQUxQSGAAAAAJcEhre948CEWoSVeEGiwGm0E+hBAsNSjChzCEFyEO/wgRMQG8dlZvv8UHj7PWwNaYwNMKZYBYFkRVJc3pphk1ddENM/nfc58wBkMZMo3oO3JHhvOlTnoz+ZzBVZTOD21WUDggMAAAAHACAJ0BKhkADgAucU6nU6gAQA4JZQAA7wJnq2AA/voE9869laYL//u61aJayAAAAA=="
                                alt="" class="checkAllInCenterImg"/>
                            <div class="checkAllInCenterDiv">
                            </div><img
                                src="data:image/webp;base64,UklGRrYAAABXRUJQVlA4WAoAAAAQAAAAGAAADQAAQUxQSGAAAAAJcEhre948CEWoSVeEGiwGm0E+hBAsNSjChzCEFyEO/wgRMQG8dlZvv8UHj7PWwNaYwNMKZYBYFkRVJc3pphk1ddENM/nfc58wBkMZMo3oO3JHhvOlTnoz+ZzBVZTOD21WUDggMAAAAHACAJ0BKhkADgAucU6nU6gAQA4JZQAA7wJnq2AA/voE9869laYL//u61aJayAAAAA=="
                                alt="" class="checkAllInCenterImg checkAllInCenterImg2"/>
                        </div>
                        <div class="checkAllInRight">
                            <div class="checkAllInRightTitle">
                                <i18n-t keypath="center.CheckCeceive">
                                    <span class="colorOrange">{{ rewardItems.days }}</span>
                                </i18n-t>
                            </div>
                            <div class="checkAllListImgs">
                                <div class="checkAllListImg" v-for="it, j in rewardItems.goodsPackDetail" :key="j">
                                    <img :src="require(`@/assets/imagine/goods/${it.cover}`)" alt=""/><span>×{{ it.count }}</span></div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                        viewBox="0 0 35 34" fill="none" :style="{'display': rewardItems.days === data.checkDay ?'':'none'}">
                                        <path
                                            d="M7.40314 11.4149L0.739746 16.9936C8.05425 22.5724 8.22972 28.3576 7.40314 30.5529C10.0644 30.5529 12.3618 30.9681 15.1511 32.5674C17.5504 21.9667 26.541 12.4996 33.7468 9.09035C31.3482 6.13123 29.3105 3.91892 26.541 0.567383C19.4716 7.936 15.1511 14.1072 12.6655 21.1063C12.6655 18.7064 10.0984 13.6414 7.40314 11.4149Z"
                                            fill="#46CB3A" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!dialog" class="codeTitle">
            <span>{{ $t('center.GiftPack') }}</span>
        </div>
        <div v-if="!dialog" class="codeContent">
            <div class="codeInputWrap">
                <div class="codeInputWrapLeft">
                    <div class="codeInputContent" ref="codeIpt">
                        <input type="text" v-model="codeInput" class="codeInput" :placeholder="$t('center.EnterGiftRedemptionCode')" @focus="changeIptBorder" @blur="rmIptBorderStyle" @change="inputChange">
                    </div>
                    <svg v-if="codeInput" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="clearSvg" style="" @click="clearValue"><path d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z" fill="#C7C7C7"></path><path d="M14.8282 9.17188L9.17139 14.8287" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.17163 9.17188L14.8285 14.8287" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </div>
                <span class="rules" @click="hiddenAlert">{{$t('center.Rules')}}</span>
            </div>
            <div class="codeBtn" @click="exchange">
                <span>{{$t('center.RedeemNow')}}</span>
            </div>
        </div>
        <div v-if="showAlert" id="rule_msg" ref="alertArea">
            <div id="rule_mask"></div>
            <div id="rule_alert_box">
                <svg data-v-37566e7a="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="MyDialogIcon" @click="hiddenAlert(false)"><path data-v-37566e7a="" d="M3.33301 3.33301L16.6663 16.6663" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-37566e7a="" d="M3.33301 16.6663L16.6663 3.33301" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <div class="MyDialogTitle">
                    <span>{{$t('center.ExchangeRules')}}</span>
                </div>
                <p>{{$t('center.ExchangeRules1')}}</p>
                <p>{{$t('center.ExchangeRules2')}}</p>
                <p>{{$t('center.ExchangeRules3')}}</p>
                <p>{{$t('center.ExchangeRules4')}}</p>
                <p>{{$t('center.ExchangeRules5')}}</p>
                <br>
                <p>{{$t('center.ExchangeRules6')}}</p>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios';
import { I18nT } from 'vue-i18n'
import { withdrawParamsToObj } from "@/util/index";

export default {
    name: "CheckInList",
    data() {
        return {
            isChecked: this.data.isChecked,
            codeInput: '',
            showAlert:false
        };
    },
    inject: ['onCheckIn'],
    computed: {
        rewardItems(){
            const days = this.data.checkDay;
            if (!this.data.cumulative){
                return {
                    days: 0,
                    goodsPackDetail: []
                };
            }
            for(let i = 0; i < this.data.cumulative.length; i++){
                if (days <= this.data.cumulative[i].days){
                    return this.data.cumulative[i];
                }
            }
            return this.data.cumulative[this.data.cumulative.length - 1];
        }
    },
    components: {
        I18nT
    },
    props: {
        data: Object,
        dialog: Boolean
    },
    watch: {
        data: {
            handler: function (val) {
                this.isChecked = val.isChecked;
            },
            deep: true
        }
    },
    created() {
        this.init();
    },
    methods: {
        init(){
            const value = localStorage.getItem("code-input-value");
            const parsedUrl = withdrawParamsToObj(this.$root.$route.fullPath);
            const giftcode = parsedUrl?.giftcode || ''
            this.codeInput = giftcode || value
        },
        async checkIn(){
            if (!this.isChecked){
                const userMsg = this.$store.state.userMsg;    // 获取当前登录的用户信息
                if (!userMsg.appid){
                    this.$store.commit('changeAlertMsg', this.$t('center.loginAgainToGift'));
                    return;
                }
                this.$store.commit("isLoadCurrent", true);
                const resp = await axios({
                    method: 'post',
                    url: 'https://webpay.ivymobile.com/check_in',
                    data: {
                        appid: userMsg.appid,
                        userId: userMsg.userId,
                        timezone: this.data.timezone,
                        date: //get current date in yyyy-mm-dd format
                            new Date().toISOString().slice(0, 10),
                        ip: this.data.ip,
                    }
                });
                if (resp.data.status === 1){
                    this.isChecked = true;
                    this.onCheckIn();
                    this.$emit('checkIn');
                    this.$store.commit('changeAlertMsg', this.$t('center.CollectionSuccessful'));
                } else if (resp.data.status === 1003){
                    this.isChecked = true;
                    this.onCheckIn();
                    this.$emit('checkIn');
                }
                this.$store.commit("isLoadCurrent", false);
            }
        },
        // 如果input 标签获取焦点
        changeIptBorder() {
            this.$refs.codeIpt.style.border = "2px solid #557be1";
        },

        // 如果输入框失去焦点，则去除 border 样式
        rmIptBorderStyle() {
            this.$refs.codeIpt.style.border = "1px solid #ddd";
        },
        // 兑换
        async exchange() {
            const userMsg = this.$store.state.userMsg;
            if (!userMsg.appid){
                this.$store.commit('changeAlertMsg', this.$t('center.loginAgainToGift'));
                return;
            }
            if(!this.codeInput){return}
            this.$store.commit("isLoadCurrent", true);
            const resp = await axios({
                method: 'post',
                url: 'https://webpay.ivymobile.com/redeem_code',
                data: {
                    appid: userMsg.appid,
                    userId: userMsg.userId,
                    redcode:this.codeInput
                }
            });
            if (resp.data.status === 200){
                this.$store.commit('changeAlertMsg', this.$t('center.successfulClaim'));
                this.$router.push({path:'/Events',query:{giftcode:this.codeInput}})
            } else if (resp.data.status === 201){
                this.$store.commit('changeAlertMsg', this.$t('center.TheGiftCodeIncorrect'));
            }else{
                this.$store.commit("changeAlertMsg", this.$t('center.TheGiftCodeHasExpired'));
            }
            this.$store.commit("isLoadCurrent", false);
        },
        // 清除
        clearValue(){
            this.codeInput = ''
            localStorage.removeItem("code-input-value");
        },
        // 兑换规则弹窗
        hiddenAlert(flag){
            this.showAlert = flag
        },
        inputChange(){
            localStorage.setItem("code-input-value", this.codeInput);
        }
    },
}
</script>

<style lang="scss" scoped>
.checkInDiv {
    border-radius: 20px;
    background: #eef1ff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),inset 0 -2.4px 2px 0 rgba(0,0,0,.06);
    margin-bottom: 32px;
    --color-primary: #557be1;
}

.checkWrapTitle {
    height: 60px;
    background: linear-gradient(180deg,#48acfa,#4680ee);
    border-radius: 20px 20px 0 0;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 60px;
    position: relative
}

.check-wave {
    width: 100%;
    position: absolute;
    bottom: -5px;
    height: 6px;
    background-image: url(data:image/webp;base64,UklGRlIBAABXRUJQVlA4WAoAAAAQAAAAMQAACgAAQUxQSIsAAAABcFXbdpw8CZUQCUiIBCTEARLAATgoTiIBB9QBODjnIwOjgIiYABBFVOwizqKiYP+0rrU+xRYF5+mpKAJbiXZ32AQQRBpUFKef57zEZF6dRGlmUe6HJV5NKyLSjFFRqDnemq44hAa7WHO8PV22BlEmao7PlptjRB7HFJ9PpyoKul1yfG3el1L2S/xJAFZQOCCgAAAAUAUAnQEqMgALAD6dQJtJJhSRFAaoWAnEtgBUlqCeB/VYkBtgOf/slMGVZaQdLFsUG43YAP5vKx45f6WlsuVcbDCrs+BFmQOPZsLeRy+Cx5srCuaxTNqmiCVFgYNY4YRoD3c/8IRSU/H7Rg12mYTP4jqFH2Fwb1RBflDW+0k2INHsdihWarPDTRIxYKRUDW/Yvpc8Yv2Ijh80NNBK+4AAAA==);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain
}

.checkWrapTitleImg {
    width: 11.5px;
    height: 39px;
    position: absolute;
    top: -10px;
    left: 115px
}

.checkWrapTitleImg2 {
    left: auto;
    right: 115px
}

.checkCenter {
    padding: 38px 32px 32px;
    box-sizing: border-box
}

.checkList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px
}

.checkListItem {
    box-shadow: 0 0 2px 0 rgba(0,0,0,.25);
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    flex: 1
}

.checkListTitle {
    background: linear-gradient(180deg,#48acfa,#4680ee);
    height: 30px;
    border-radius: 10px 10px 0 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 30px;
    position: relative
}

.checkListTitleColumn {
    display: inline-block;
    width: 6.2px;
    height: 16px;
    border-radius: 31px;
    background: #fff;
    box-shadow: inset 0 0 2px 0 rgba(100,215,253,.8);
    position: absolute;
    top: -6px;
    left: 14px
}

.checkListTitleColumn1 {
    left: auto;
    right: 14px
}

.checkListTitleAlready {
    background: linear-gradient(180deg,#6adffd,#44affa)
}

.checkListTitleBorder {
    border: 1px solid #49b5fb
}

.checkListImgs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 16px 13px
}

.checkListImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.checkListImg img {
    width: 52px;
    height: 52px
}

.checkListImg span {
    font-size: 12px;
    font-weight: 800;
    color: #666
}

.isCheckIn {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 33px;
    height: 32px
}

.checkBottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 25px
}

.headerCheckIn .checkBottom {
    flex-direction: column
}

.checkBottomButton {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.checkBottomButton button {
    border: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    padding: 12px 0;
    border-radius: 8px;
    background: var(--color-primary);
    min-width: 144px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer
}

.checkBottomButton button:hover {
    background-color: #4869bf
}

.checkBottomButton p {
    color: #6d84c7;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-top: 16px
}

.checkBottomButton .alreadyCheck {
    background: #d6d6d6;
    color: #888
}

.checkBottomButton .alreadyCheck:hover {
    background-color: #d6d6d6
}

.checkAllIn {
    border-radius: 12px;
    background: #fff;
    box-shadow: inset 0 -1px 0 0 #cfc0d9;
    display: flex;
    align-items: stretch;
    min-width: 65%
}

.checkAllInLeft {
    color: #666;
    font-size: 14px;
    font-weight: 600;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(data:image/webp;base64,UklGRmACAABXRUJQVlA4WAoAAAAQAAAAkgAAfQAAQUxQSFEBAAABgCJb2/PklXRQVaF+Re8yxdJsHIqJoxcX6gKQWQH+zQK+DXyrSvnKMO+d1JmIcOS2jSTJUs9+na43yP9u6e49spIQbOkuGoSRMAwGYiAUe2Lx+v3tVMZ093sChGoVCCKVSCVSiVQilUgNSIRuEnFLxE+fdFnui35W7YvuGtW+6ARS7YtOs1XB4Q8omZUx6Z3e3V1NwVB6b68+RVO3LPVKUkiOwlIUkyEcl94TdmqejRSuzDOVwi7R20g/I/o/JLprEN1biU4gSOc0lu3W366mZPJwRe39vbbOwqPv2g0JDe97RCkgSEExighRVIQSBChJ8yVqvGRNl6HhcHz0Hsdzn70DgzTzeTSIy6dlEE9EK58mzy+9PzfImstF58XgzjJx6yI0ukLEqJmZNSvDZmTa5IybmHmTAjAhBKNBGAlDx5PIWqPz7H1dWDZ/UMzLhOoEAFZQOCDoAAAAUAwAnQEqkwB+AD6dSqBKtAlKMhIsBOJaW7dXvYqf4Ae/0HZrcMlixcTetn9nZhg4np58Hj0M5W+E1hqzx1TmFbfdXk/zDN7w2SfLRGKVEBuDiVnjqZpjGVIp1krPHZeOUkZz2WS+KBx+OoAA/vLTTffhZZdIt2m3+GW/gsAgEiNHvu6X6lypKDyAEh/wIf/9UZ//U6H//Uptb0IGoG72Ir+TnTOx+m9DGL//9uo+5DkUf8yg+Tgtdqj8V81Q28JD+BSDPtA5iGpctebSvKCM060LHM2E+HR16hSoICLWgZ9mafznHQAAAA==);
    background-position: bottom;
    background-size: contain;
    text-align: center;
    box-sizing: border-box;
    padding: 10px
}

.checkAllInLeft span {
    color: var(--color-primary);
    font-size: 28px;
    font-weight: 800
}

.checkAllInCenter {
    width: 28px;
    position: relative
}

.checkAllInCenterDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 17px;
    left: 12px;
    height: calc(100% - 40px)
}

.checkAllInCenterDiv div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #eef1ff
}

.checkAllInCenterImg {
    position: absolute;
    top: -1px;
    width: 28px;
    height: 12px
}

.checkAllInCenterImg2 {
    position: absolute;
    top: auto;
    bottom: -1px;
    transform: rotateX(180deg)
}

.checkAllInRight {
    flex: 1;
    text-align: center;
    padding: 10px 20px
}

.checkAllInRightTitle {
    color: #999;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px dashed #e8e8e8;
    padding-bottom: 10px;
    margin-bottom: 10px
}

.oneGet {
    color: var(--color-primary);
    text-decoration-line: underline;
    cursor: pointer
}

.colorOrange {
    color: #ff6d3c
}

.checkAllListImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px
}

.checkAllListImg {
    position: relative
}

.checkAllListImg img {
    width: 52px;
    height: 52px
}

.checkAllListImg span {
    position: absolute;
    left: 52px;
    bottom: 0;
    color: #666;
    font-size: 12px;
    font-weight: 800
}

.checkAllInBorder {
    display: none
}

@media(max-width: 767px) {
    .checkInDiv {
        margin-bottom:24px
    }

    .checkCenter {
        padding: 20px 6px 16px
    }

    .check-wave {
        bottom: -3px;
        height: 4px;
        background-image: url(data:image/webp;base64,UklGRtIAAABXRUJQVlA4WAoAAAAQAAAAHAAABQAAQUxQSFEAAAABcEjbtlUdhIuQSZmUQQiFEEIGIVyEi3ARMlir/3sIEZE5FxRR8YF472NEpL7ijYKv3MVrqAIIImpu4yvN+EHt41ezgojsU4o/0rCVWpYuPgAAVlA4IFoAAADQAgCdASodAAYAAkA4JbACdLwJ5AngDeUQgIwVEccAAP7kUuRcxb8R5uPNuqBPn2P4CywT+eSSyuuxiW7Sr1/2YM6V2Pb88Q/8fPwtM0hdhmyvD6u4B+AAAAA=)
    }

    .checkWrapTitleImg {
        left: 53px
    }

    .checkWrapTitleImg2 {
        left: auto;
        right: 53px
    }

    .checkList {
        gap: 6px
    }

    .checkListTitle {
        font-size: 14px
    }

    .checkListItem {
        margin-top: 8px
    }

    .checkListTitleColumn {
        width: 5px;
        height: 13px
    }

    .checkListImgs {
        margin: 5px 16px
    }

    .checkListImg img {
        width: 44px;
        height: 44px
    }

    .isCheckIn {
        position: absolute;
        bottom: -6px;
        right: -6px
    }

    .checkBottom {
        display: flex;
        flex-direction: column;
        gap: 0
    }

    .checkAllInLeft {
        min-width: 96px;
        max-width: 100px;
        word-break: break-all
    }

    .checkAllInRight {
        padding: 11px
    }

    .checkBottomButton {
        max-width: 100%;
        padding-bottom: 24px
    }

    .checkBottomButton button {
        padding: 8px 24px
    }

    .checkAllInBorder {
        border-top: 1px dashed #c3c6d3;
        padding-top: 16px
    }

    .checkAllListImgs {
        flex-wrap: wrap
    }

    .checkAllListImg img {
        width: 36px;
        height: 36px
    }

    .checkAllListImg span {
        left: 36px
    }
}
.codeTitle{
    color: #333;
    font-size: large;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.codeContent, .codeInputWrap {
    display: flex;
    align-items: center;
}
.codeInputWrap {
    max-width: 100%;
    margin-bottom: 20px;
}

.codeContent {
    width: 100%;
    border-radius: 12px;
    background: #eef1ff;
    flex-direction: column;
    padding: 80px 0;
    .codeBtn{
        padding: 12px 24px;
        background-color: #557be1;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        min-width: 80px;
        @media (max-width: 600px) {
            padding: 8px 24px;
        }
    }
    .codeInputWrapLeft {
        position: relative;
    }
    .codeInputContent{
        font-size: 14px;
        font-weight: 400;
        height: 50px;
        padding: 0 6px;
        border-radius: 8px;
        border: none;
        background: #fff;
        color: #333;
        box-sizing: border-box;
        text-align: center;
        max-width: 640px;
        min-width: 540px;
        @media (max-width: 600px) {
            height: 36px;
            max-width: 400px;
            min-width: 300px;
        }
        .codeInput,.codeInput:focus,.codeInput:focus-visible{
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
        }
    }
    .clearSvg{
        position: absolute;
        right: 8px;
        top: 12px;
        @media (max-width: 600px) {
            right: 8px;
            top: 5px;
        }
    }
    .rules {
        color: #a6bdf7;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;
        margin-left: 10px;
    }
}
  /*#alert_msg {*/
  #rule_mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.3;
    z-index: 888;
    }
  #rule_alert_box {
    position: fixed;
    // width: 100%;
    max-width: 540px;
    padding: 24px;
    // height: 200px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    z-index: 889;
    border-radius: 15px;
    p{
        line-height: 1.5;
        font-size: 14px;
        color: #666;
        font-weight: 400;
    }
  }
@media (max-width: 600px) {
    #rule_alert_box {
        width: 80%;
    }
}
.MyDialogIcon {
    position: absolute;
    top: 8px;
    right: 8px;
}
  .MyDialogTitle{
    color: #333;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
  }
</style>